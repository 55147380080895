import {Component, Input, OnInit} from '@angular/core';
import {IEditForm} from "../edit-dialog/edit-dialog.component";
import {Customer, ExecuteCustomerUpdate} from "@io-elon-common/frontend-api";
import {CustomerService} from "../../../../services/api-handlers/customer.service";
import {BehaviorSubject} from "rxjs";

@Component({
    selector: 'app-edit-customer',
    templateUrl: './edit-customer.component.html',
    styleUrls: ['./edit-customer.component.scss']
})
export class EditCustomerComponent implements OnInit, IEditForm<ExecuteCustomerUpdate> {

    @Input()
    data!: ExecuteCustomerUpdate;

    @Input()
    public currentCustomerId!: number;

    customers: BehaviorSubject<Customer[] | undefined>

    constructor(
        private readonly customerService: CustomerService
    ) {
        this.customers = customerService.getAll();
    }

    ngOnInit(): void {
    }

    validate(): string[] {
        const ret = []
        if (!this.data.name || this.data.name.trim().length === 0) {
            ret.push("Kein Name eingegeben");
        }

        if (this.data.customerParent === this.currentCustomerId) {
            ret.push("Kunden können nicht einem Kunden untergeordnet werden, den sie selbst managen");
        }
        return ret;
    }
}
