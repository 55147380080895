<div *ngIf="customers | async as customers; else loading" class="d-flex flex-column gap-3">
    <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput type="text" [(ngModel)]="data.name">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Übergeordneter Reseller</mat-label>
        <mat-select [(ngModel)]="data.customerParent">
            <mat-option [value]="undefined">Keiner</mat-option>
            <ng-container *ngFor="let c of customers">
                <mat-option *ngIf="c.id !== currentCustomerId" [value]="c.id">{{c.name}}</mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>
</div>
<mat-checkbox [(ngModel)]="data.reseller">Ist ein Reseller</mat-checkbox>
<ng-template #loading>
    Laden...
</ng-template>
