import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Customer, ExecuteCustomerAdd, ExecuteCustomerUpdate, FleetHierarchy} from '@io-elon-common/frontend-api';
import {BehaviorSubject} from 'rxjs';
import {CacheUpdater} from './cacheManager';
import {DialogHandler} from "./dialog-handler";
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {DialogService} from "../dialog.service";
import {IEditForm, TDialogOptions} from "../../shared/components/dialogs/edit-dialog/edit-dialog.component";
import {EditCustomerComponent} from "../../shared/components/dialogs/edit-customer/edit-customer.component";
import {NewCustomerComponent} from "../../shared/components/dialogs/new-customer/new-customer.component";
import {ApiHandler} from "./api-handler";
import {AuthService} from "../../shared/guards/auth.service";
import {POLL_INTERVALS} from "../../app.module";

@Injectable({
    providedIn: 'root'
})
export class CustomerService extends DialogHandler<Customer, ExecuteCustomerUpdate, ExecuteCustomerAdd, never, void, void> {
    private hierarchyCache: CacheUpdater<FleetHierarchy, number>;

    constructor(
        apiService: ApiService,
        toastr: ToastrService,
        dialog: MatDialog,
        dialogService: DialogService,
        private readonly authService: AuthService
    ) {
        super(apiService, "Customer", toastr, dialog, dialogService, POLL_INTERVALS.customer);
        this.hierarchyCache = this.createManagedCache((obj, id) => obj.id === id);
    }

    public getFleetHierarchy(id: number, showAlerts = true): BehaviorSubject<FleetHierarchy | undefined> {
        return this.hierarchyCache.getOrCreateGet(id, () => this.apiService.getFleetHierarchy(showAlerts, id, undefined, undefined).toPromise()).data;
    }

    protected getEditConfig(element: Customer): TDialogOptions<ExecuteCustomerUpdate, EditCustomerComponent> {
        return {
            headline: "Kunde bearbeiten",
            component: EditCustomerComponent,
            executeCallback: editResult => this.updateWithoutCustomerId(element.id, editResult),
            editElement: {
                name: element.name,
                customerParent: element.parentId as number,
                reseller: element.isReseller || false
            },
            extraParams: {
                currentCustomerId: element.id as number
            }
        };
    }

    protected getNewConfig(): TDialogOptions<ExecuteCustomerAdd, IEditForm<ExecuteCustomerAdd>> | Promise<TDialogOptions<ExecuteCustomerAdd, IEditForm<ExecuteCustomerAdd>>> {
        return {
            headline: "Kunde anlegen",
            component: NewCustomerComponent,
            executeCallback: editResult => this.create(editResult),
            editElement: {
                name: "",
                customerParent: (ApiHandler.customerId != undefined ? ApiHandler.customerId : this.authService.currentUserSubj.getValue()?.customerId) as number,
                lat: 54.7747222,
                lon: 9.497666388888888,
                fleetName: "",
                energyPrice: 0.3,
                netzentgelt: 1.9,
                customerName: "",
                customerReseller: false,
                psName: "",
                dynamicElectricityCostsEnabled: false,
                konzessionsabgabe: 7.07,
                psMaxAmps: 54,
                psGridConnectionId: "",
                psPeakCalculationIntervall: "YEARS",
                icon: ""
            }
        };
    }


}
